import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import AboutArea from "@containers/index-processing/about-area"
import ContactArea from "@containers/index-infotechno/contact-area"
import PageHeader from "@components/pageheader";


import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

import slide3 from "@assets/images/OIU9IB0.jpg"

const IndexProcessing = ({ location ,pageContext}) => {
  const intl = useIntl()
  const serviceTitles = intl.formatMessage({
    id: "modification_of_the_design_and_rehabilitation_existing_buildings",
  })
  const content = [
    
    {
      image: slide3,
    },
  ]

  const srviceTexts = [
    {
      text: <FormattedMessage id="Redistribution_of_architectural_spaces" />,
    },
    {
      text: <FormattedMessage id="Preparing_architectural" />,
    },
    {
      text: <FormattedMessage id="preparing_the_schedules_materials" />,
    },
    {
      text: <FormattedMessage id="Conducting_the_necessary_matches" />,
    },
    {
      text: (
        <FormattedMessage id="Provide_screenshots_project_with_3D_output" />
      ),
    },
  ]

  return (
    <Layout location={location}>
      <Seo title="Services" />
      <Header />
      <main className="site-wrapper-reveal">
        {/* <HeroArea /> */}
        <PageHeader
			pageContext={pageContext}
			location={location}
			title="IT Services"
      pageTitle="services"
		/>
        <AboutArea
          content={content}
          srviceTexts={srviceTexts}
          serviceTitles={serviceTitles}
          studyRequestUrl="/study-request-modifyProject"
          isStudyRequest={true}
          buttonTextRequest="study_request"
          stopPointer="stopPointer"
        />

        <ContactArea />
      </main>
      <Footer />
    </Layout>
  )
}

export default IndexProcessing
